interface MarkPlugin {
    mark(words: string[], options?: object): void
}

$.fn.extend({
    'searchWordHighlight': function (this: JQuery, options) {
        // IE はサポートしない
        if (typeof URLSearchParams === 'undefined') {
            return
        }

        options = $.extend({
            paramName: 'w',
            selector: ".body",
            limit: 10,
            markedClassResolver: i => "mark-" + i,
        }, options)

        const usp = new URLSearchParams(location.search)
        if (!usp.has(options.paramName)) {
            this.hide()
            return
        }

        const words = usp.get(options.paramName).split(/\s+/).slice(0, options.limit)
        if (words.length === 0 || (words.length === 1 && words[0].length === 0)) {
            this.hide()
            return
        }

        this.show()

        // 表記ゆれを想定しすぎている問題があるのでいったんオフ
        // let synonyms = $.extend(characterVariants(), compositeVariants());
        let synonyms = []

        const $wordList = this.find('.search-words-words')
        const $markingTarget = $(options.selector) as JQuery & MarkPlugin
        $.each(words, function (i) {
            const word = this
            const className = options.markedClassResolver(i)
            $('<li>').append($('<mark>').text(word).addClass(className)).appendTo($wordList)
            $markingTarget.mark([word], {
                className: className,
                synonyms: synonyms, // diacritics: true は日本語の濁点・半濁点に対応してないため synonyms で同一視する文字の対応を指定
            })
        })
    },
})

// /**
//  * http://www.unicode.org/charts/PDF/UFF00.pdf
//  * Halfwidth and Fullwidth Forms
//  * Range: FF00–FFEF
//  * @returns {{}}
//  * @private
//  */
// function characterVariants() {
//     let fullwidth_ascii_variants = {} // ASCII範囲は連続対応
//     for (let cp = 0xFF01; cp <= 0xFF5E; cp++) {
//         let half = cp - (0xFF01 - 0x0021)
//         fullwidth_ascii_variants[half] = cp
//     }
//     const fullwidth_brackets = {
//         [0xFF5F]: 0x2985,
//         [0xFF60]: 0x2986,
//     }
//     const halfwidth_cjk_punctuation = {
//         [0xFF61]: 0x3002,
//         [0xFF62]: 0x300C,
//         [0xFF63]: 0x300D,
//         [0xFF64]: 0x3001,
//     }
//     const halfwidth_katakana_variants = {
//         [0xFF65]: 0x30FB,
//         [0xFF66]: 0x30F2,
//         [0xFF67]: 0x30A1,
//         [0xFF68]: 0x30A3,
//         [0xFF69]: 0x30A5,
//         [0xFF6A]: 0x30A7,
//         [0xFF6B]: 0x30A9,
//         [0xFF6C]: 0x30E3,
//         [0xFF6D]: 0x30E5,
//         [0xFF6E]: 0x30E7,
//         [0xFF6F]: 0x30C3,
//         [0xFF70]: 0x30FC,
//         [0xFF71]: 0x30A2,
//         [0xFF72]: 0x30A4,
//         [0xFF73]: 0x30A6,
//         [0xFF74]: 0x30A8,
//         [0xFF75]: 0x30AA,
//         [0xFF76]: 0x30AB,
//         [0xFF77]: 0x30AD,
//         [0xFF78]: 0x30AF,
//         [0xFF79]: 0x30B1,
//         [0xFF7A]: 0x30B3,
//         [0xFF7B]: 0x30B5,
//         [0xFF7C]: 0x30B7,
//         [0xFF7D]: 0x30B9,
//         [0xFF7E]: 0x30BB,
//         [0xFF7F]: 0x30BD,
//         [0xFF80]: 0x30BF,
//         [0xFF81]: 0x30C1,
//         [0xFF82]: 0x30C4,
//         [0xFF83]: 0x30C6,
//         [0xFF84]: 0x30C8,
//         [0xFF85]: 0x30CA,
//         [0xFF86]: 0x30CB,
//         [0xFF87]: 0x30CC,
//         [0xFF88]: 0x30CD,
//         [0xFF89]: 0x30CE,
//         [0xFF8A]: 0x30CF,
//         [0xFF8B]: 0x30D2,
//         [0xFF8C]: 0x30D5,
//         [0xFF8D]: 0x30D8,
//         [0xFF8E]: 0x30DB,
//         [0xFF8F]: 0x30DE,
//         [0xFF90]: 0x30DF,
//         [0xFF91]: 0x30E0,
//         [0xFF92]: 0x30E1,
//         [0xFF93]: 0x30E2,
//         [0xFF94]: 0x30E4,
//         [0xFF95]: 0x30E6,
//         [0xFF96]: 0x30E8,
//         [0xFF97]: 0x30E9,
//         [0xFF98]: 0x30EA,
//         [0xFF99]: 0x30EB,
//         [0xFF9A]: 0x30EC,
//         [0xFF9B]: 0x30ED,
//         [0xFF9C]: 0x30EF,
//         [0xFF9D]: 0x30F3,
//         [0xFF9E]: 0x3099,
//         [0xFF9F]: 0x309A,
//     }
//     let halfwidth_hangul_variants = {
//         [0xFFA0]: 0x3164,
//     }
//     for (let cp = 0xFFA1; cp <= 0xFFBE; cp++) {
//         let half = cp - (0xFFA1 - 0x3131)
//         halfwidth_hangul_variants[half] = cp
//     }
//     for (let cp = 0xFFC2; cp <= 0xFFC7; cp++) {
//         let half = cp - (0xFFC2 - 0x314F)
//         halfwidth_hangul_variants[half] = cp
//     }
//     for (let cp = 0xFFCA; cp <= 0xFFCF; cp++) {
//         let half = cp - (0xFFCA - 0x3155)
//         halfwidth_hangul_variants[half] = cp
//     }
//     for (let cp = 0xFFD2; cp <= 0xFFD7; cp++) {
//         let half = cp - (0xFFD2 - 0x315B)
//         halfwidth_hangul_variants[half] = cp
//     }
//     for (let cp = 0xFFDA; cp <= 0xFFDC; cp++) {
//         let half = cp - (0xFFDA - 0x3161)
//         halfwidth_hangul_variants[half] = cp
//     }
//     const fullwidth_symbol_variants = {
//         [0xFFE0]: 0x00A2,
//         [0xFFE1]: 0x00A3,
//         [0xFFE2]: 0x00AC,
//         [0xFFE3]: 0x00AF,
//         [0xFFE4]: 0x00A6,
//         [0xFFE5]: 0x00A5,
//         [0xFFE6]: 0x20A9,
//     }
//     const halfwidth_symbol_variants = {
//         [0xFFE8]: 0x2502,
//         [0xFFE9]: 0x2190,
//         [0xFFEA]: 0x2191,
//         [0xFFEB]: 0x2192,
//         [0xFFEC]: 0x2193,
//         [0xFFED]: 0x25A0,
//     }
//     const codePointTable = $.extend(
//         fullwidth_ascii_variants, fullwidth_brackets, halfwidth_cjk_punctuation, halfwidth_katakana_variants,
//         halfwidth_hangul_variants, fullwidth_symbol_variants, halfwidth_symbol_variants)
//     let stringTable = {}
//     for (const codePoint in codePointTable) {
//         const variant = String.fromCodePoint(parseInt(codePoint))
//         stringTable[variant] = String.fromCodePoint(codePointTable[codePoint])
//     }
//     return stringTable
// }
//
// /**
//  * カタカナと濁点・半濁点あわせて２文字になったバリエーション
//  * @returns {{}}
//  */
// function compositeVariants() {
//     return {
//         ["ｶ" + "ﾞ"]: "ガ",
//         ["ｷ" + "ﾞ"]: "ギ",
//         ["ｸ" + "ﾞ"]: "グ",
//         ["ｹ" + "ﾞ"]: "ゲ",
//         ["ｺ" + "ﾞ"]: "ゴ",
//         ["ｻ" + "ﾞ"]: "ザ",
//         ["ｼ" + "ﾞ"]: "ジ",
//         ["ｽ" + "ﾞ"]: "ズ",
//         ["ｾ" + "ﾞ"]: "ゼ",
//         ["ｿ" + "ﾞ"]: "ゾ",
//         ["ﾀ" + "ﾞ"]: "ダ",
//         ["ﾁ" + "ﾞ"]: "ヂ",
//         ["ﾂ" + "ﾞ"]: "ヅ",
//         ["ﾃ" + "ﾞ"]: "デ",
//         ["ﾄ" + "ﾞ"]: "ド",
//         ["ﾊ" + "ﾞ"]: "バ",
//         ["ﾋ" + "ﾞ"]: "ビ",
//         ["ﾌ" + "ﾞ"]: "ブ",
//         ["ﾍ" + "ﾞ"]: "ベ",
//         ["ﾎ" + "ﾞ"]: "ボ",
//         ["ﾊ" + "ﾟ"]: "ボ",
//         ["ﾋ" + "ﾟ"]: "ピ",
//         ["ﾌ" + "ﾟ"]: "プ",
//         ["ﾍ" + "ﾟ"]: "ペ",
//         ["ﾎ" + "ﾟ"]: "ポ",
//         ["ｳ" + "ﾞ"]: "ヴ",
//     }
// }
