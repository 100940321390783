import ShareButton from './components/ShareButton.svelte'

$.fn.extend({
    'sharePanel': function (this: JQuery, options) {
        options = $.extend({
            title: '',
            controlPanelUrl: '',
            imageUrl: '',
            description: '',
            shareUrl: '',
            hasOgSettings: false,
        }, options)

        new ShareButton({
            target: this.get(0),
            props: options
        })
    }
})
