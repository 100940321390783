<script lang="ts">
    import FloatingPanel from "./FloatingPanel.svelte"
    import ShareButtonContent from './ShareButtonContent.svelte'

    export let title: string
    export let controlPanelUrl: string
    export let imageUrl: string
    export let description: string
    export let shareUrl: string
    export let hasOgSettings: boolean

    const style = {
        width: '300px',
        maxHeight: '400px',
    }

    const props = {
        title,
        controlPanelUrl,
        imageUrl,
        description,
        shareUrl,
    }
</script>

<FloatingPanel panelId="share-button" style={style} triggerColor={hasOgSettings ? 'success' : ''}>
    <i slot="trigger" class="fa-solid fa-share-nodes" />
    <ShareButtonContent slot="content" {...props} />
</FloatingPanel>
