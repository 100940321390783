<script lang="ts">
    import {onMount} from 'svelte'
    import {Instance} from 'tippy.js'
    import ControlPanelLink from './ControlPanelLink.svelte'

    export let title: string
    export let controlPanelUrl: string
    export let imageUrl: string
    export let description: string
    export let shareUrl: string

    const EMPTY_DESCRIPTION = 'WIKIの説明が未設定です'

    let copyButton: HTMLButtonElement
    let sharePanelCopyTooltip: Instance

    onMount(() => {
        sharePanelCopyTooltip = window.tippy(copyButton, {
            content: 'タイトルとリンクをコピーしました',
            trigger: 'manual',
        })

        return () => {
            sharePanelCopyTooltip.destroy()
        }
    })

    function handleClickCopy() {
        if (!navigator.clipboard) {
            alert('このブラウザはクリップボードへのコピーに対応していません。お手数ですが、URLを手動でコピーしてください。')
            return
        }

        navigator.clipboard.writeText(`${title} ${shareUrl}`).then(function() {
            sharePanelCopyTooltip.show()
        }).catch(function() {
            alert('クリップボードへのコピーに失敗しました。')
        })
    }

    function handleClickShare() {
        navigator.share({
            title,
            text: description,
            url: shareUrl,
        })
    }
</script>

<div class="share-panel">
    <div class="share-panel-title">
        <span class="title-text">{title}</span>
        <ControlPanelLink url={controlPanelUrl} />
    </div>
    <img src="{imageUrl}" alt="OGPイメージ" class="share-panel-image" loading="lazy">
    <div class="share-panel-description" class:no-description={!description}>{description || EMPTY_DESCRIPTION}</div>
    <div class="share-panel-buttons">
        <input class="share-panel-share-url" value="{shareUrl}" disabled>
        <div class="share-panel-sns-button">
            <button bind:this={copyButton} on:click={handleClickCopy}
                    type="button" class="share-panel-copy-button" title="クリップボードにコピー">
                <i class="fa-solid fa-paperclip"></i>
            </button>
        </div>
        {#if navigator.share}
            <button on:click={handleClickShare} type="button" class="share-panel-buttons native-share-button" title="共有">
                <i class="fa-solid fa-share-from-square"></i>
            </button>
        {/if}
    </div>
</div>

<style>
    .share-panel {

        & .share-panel-title {
            font-size: 14px;
            font-weight: bold;
            display: flex;
            justify-content: space-between;
            align-items: start;
            margin-bottom: 8px;

            & .title-text {
                max-width: 90%;
            }
        }

        & .share-panel-buttons {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding: 4px;

            & .share-panel-share-url {
                font-size: 12px;
                flex: auto;
                width: 100%;
                border: solid 1px #aaa;
                background: #eee;
                padding: 4px;
            }

            & button {
                background: none;
                border: none;
                padding: 4px;
            }
        }

        & .share-panel-description {
            font-size: 12px;
            margin-top: 8px;
            margin-bottom: 8px;

            &.no-description {
                color: #ccc;
            }
        }

        & .share-panel-image {
            max-width: 100%;
            height: auto;
            border: solid 1px #ccc;
            margin: auto;
            display: block;
        }
    }
</style>
